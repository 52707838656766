import React from 'react';
import { Link } from 'gatsby';

import { Button, Title, UnderlinedHeadline } from '../components/global';

const DatenschutzPage = () => (
  <>
    <Title prefix="Datenschutz" />
    <section className="bg-champagne-blue">
      <UnderlinedHeadline as="h1" containerClasses="text-center px-4">
        Datenschutz
      </UnderlinedHeadline>
    </section>
    <section>
      <div className="data-privacy container container--narrow space-y-10">
        <p>Stand: 08.06.2022</p>
        <p>
          Mit diesen Hinweisen informieren wir dich über die Verarbeitung deiner
          personenbezogenen Daten durch die CodeCamp:N GmbH und die dir nach dem
          Datenschutzrecht zustehenden Rechte. Verantwortliche Stelle im Sinne
          der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung
          (DS-GVO), ist:
        </p>
        <ul>
          <li>CodeCamp:N GmbH</li>
          <li>Solgerstraße 18</li>
          <li>90429 Nürnberg</li>
          <li>
            Telefon: <a href="tel:+4991127448523">+49 (0) 911 27448523</a>
          </li>
          <li>
            <a href="mailto:info@codecamp-n.com">info@codecamp-n.com</a>
          </li>
        </ul>
        <h2>Wir haben folgenden Datenschutzbeauftragten bestellt:</h2>
        <ul>
          <li>Herr Dr. Thomas Balzer</li>
          <li>bpc GmbH</li>
          <li>Einigkeitstraße 9</li>
          <li>645133 Essen</li>
          <li>
            Telefon: <a href="tel:+492018908810">+49 201 890 881-0</a>
          </li>
          <li>
            <a href="mailto:info@b-p-c.eu">info@b-p-c.eu</a>
          </li>
        </ul>
        <h2>Deine Betroffenenrechte</h2>
        <p>
          Du kannst unter der E-Mail-Adresse info@codecamp-n.com Auskunft über
          die zu deiner Person gespeicherten Daten verlangen. Darüber hinaus
          kannst du unter bestimmten Voraussetzungen die Berichtigung oder die
          Löschung deiner Daten verlangen. Dir kann weiterhin ein Recht auf
          Einschränkung der Verarbeitung deiner Daten sowie ein Recht auf
          Herausgabe der von dir bereitgestellten Daten in einem strukturierten,
          gängigen und maschinenlesbaren Format zustehen.
        </p>
        <h2>Beschwerderecht</h2>
        <p>
          Du hast die Möglichkeit, dich mit einer Beschwerde an uns, den o. g.
          Datenschutzbeauftragten oder an eine Datenschutzaufsichtsbehörde zu
          wenden. Die für uns zuständige Datenschutzaufsichtsbehörde  ist:
        </p>
        <ul>
          <li>Bayerisches Landesamt für Datenschutzaufsicht</li>
          <li>Promenade 1891522 Ansbach</li>
          <li>Postanschrift: Postfach 606, 91511 Ansbach</li>
          <li>
            Telefon: <a href="tel:+499811800930">0981/180093-0</a>
          </li>
          <li>Telefax: 0981/180093-99</li>
          <li>
            E-Mail:
            <a href="mailto:poststelle@lda.bayern.de">
              poststelle@lda.bayern.de
            </a>
          </li>
          <li>
            <a href="http://www.lda.bayern.de">http://www.lda.bayern.de</a>
          </li>
        </ul>
        <h2>Widerspruchsrecht</h2>
        <p>
          Du hast das Recht, einer Verarbeitung deiner personenbezogenen Daten
          zu Zwecken der Direktwerbung zu widersprechen. Verarbeiten wir deine
          Daten zur Wahrung berechtigter Interessen, kannst du dieser
          Verarbeitung widersprechen, wenn sich aus deiner besonderen Situation
          Gründe ergeben, die gegen die Datenverarbeitung sprechen.
        </p>
        <h2>Recht auf Datenübertragbarkeit</h2>
        <p>
          Dir steht das Recht zu, Daten, die wir auf Grundlage deiner
          Einwilligung oder in Erfüllung eines Vertrags automatisiert
          verarbeiten, an dich oder an Dritte aushändigen zu lassen. Die
          Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern du
          die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangst, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h2>
          Speicherung sowie Recht auf Löschung und Einschränkung der
          Verarbeitung der Daten
        </h2>
        <p>
          Du hast das Recht, von dem Verantwortlichen zu verlangen, dass die
          dich betreffenden personenbezogenen Daten unverzüglich gelöscht
          werden.
        </p>
        <p>
          Wir halten uns an die Grundsätze der Datenvermeidung und
          Datensparsamkeit. Wir speichern deine personenbezogenen Daten daher
          nur so lange, wie dies zur Erreichung der hier genannten Zwecke
          erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen
          vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen
          Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten
          routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt
          oder gelöscht. Rechtsgrundlage wäre die gesetzliche Vorgabe nach Art.
          6 Abs. 1 S. 1 lit. c) DS-GVO.
        </p>

        <h2>Datenübermittlung an Drittstaaten außerhalb des EWR</h2>
        <p>
          Alle Informationen, die wir von Ihnen oder über Sie erhalten, werden
          von uns grundsätzlich auf Servern innerhalb der Europäischen Union
          verarbeitet. Eine Übermittlung Ihrer Daten an oder eine Verarbeitung
          Ihrer Daten in Drittstaaten erfolgt durch uns ohne Ihre ausdrückliche
          Einwilligung lediglich, sofern dies gesetzlich vorgesehen oder
          gestattet ist und in dem Drittstaat ein hierfür angemessenes
          Datenschutzniveau sichergestellt ist.
        </p>
        <h2>Risikohinweis für Datenübermittlung in die USA</h2>
        <p>
          In unserer globalisierten Gesellschaft ist es schwierig, die
          Datenverarbeitung von persönlichen Informationen im Internet an einen
          bestimmten Ort zu knüpfen. Viele der großen Technologie-Firmen
          (Google, Facebook, Amazon etc.) haben ihren Stammsitz in den USA. Ohne
          die Technologien dieser Unternehmen wäre das Internet, wie wir es
          kennen, nicht denkbar. Auch wir nutzen deshalb Technologien, die auf
          US-amerikanische Unternehmen zurückgehen. Zwar haben die
          US-amerikanischen Unternehmen europäische Tochterfirmen mit der
          Datenverarbeitung im europäischen Wirtschaftsraum beauftragt, deren
          Dienste wir in Anspruch nehmen. Diensteanbieter im europäischen
          Wirtschaftsraum und damit unsere Vertragspartner sind diese
          europäischen Tochterfirmen der US-amerikanischen Unternehmen. Selbst
          wenn Sie Ihren gewöhnlichen Aufenthalt aber im europäischen
          Wirtschaftsraum und die Diensteanbieter hier ihren Sitz haben, können
          durch unternehmensinterne Gestaltungen der Diensteanbieter
          personenbezogene Daten letztlich in die USA übermittelt werden.
        </p>
        <p>
          Um zu gewährleisten, dass die übertragenen Daten dort ebenfalls nach
          einem der Europäischen Union vergleichbaren Datenschutzniveau
          verarbeitet werden, hatten die EU und die USA ursprünglich ein
          Abkommen zum sogenannten „EU-US-Privacy-Shield“ (zu deutsch:
          EU-US-Datenschutzschild) geschlossen. Das Privacy-Shield-Abkommen
          regelt den Schutz personenbezogener Daten, die aus einem
          Mitgliedsstaat der Europäischen Union in die USA übertragen werden.
          Unternehmen können sich nach dem Abkommen zertifizieren, wenn Sie
          bestimmte Datenschutzstandards einhalten. Die Liste zertifizierter
          Unternehmen können Sie hier abrufen:
          <a href="https://www.privacyshield.gov/list">
            https://www.privacyshield.gov/list
          </a>
        </p>
        <p>
          Mit Urteil vom 16.07.2020 (Az.: C-311/18) hat der Europäische
          Gerichtshof gleichwohl entschieden, dass die USA generell kein
          Drittland sind, in dem ein angemessenes Schutzniveau für
          personenbezogene Daten sichergestellt wäre. Das Recht und die Praxis
          der USA böten demnach keinen ausreichenden Schutz der in diesem Land
          gespeicherten personenbezogenen Daten vor den Überwachungstätigkeiten
          der dortigen Behörden, insbesondere für Nicht-US-Bürger.
        </p>
        <p>
          <b>Es besteht das Risiko, </b>
          dass Ihre in die USA übermittelten Daten den dortigen Behörden,
          insbesondere den Nachrichtendiensten NSA, CIA und FBI, bekannt werden
          und von diesen zu nachrichtendienstlichen Zwecken, zu Zwecken der
          öffentlichen Sicherheit und der Landesverteidigung verarbeitet werden.
          Die Behörden der USA unterliegen nicht den Einschränkungen der DS-GVO.
          Wie wahrscheinlich eine Kenntnisnahme Ihrer personenbezogenen Daten
          durch die Behörden der USA ist und welche Folgen die etwaige
          Verarbeitung Ihrer Daten durch US-amerikanische Behörden für Sie haben
          kann, ist einzelfallabhängig und uns unbekannt. Die Entscheidung des
          EuGH lässt die Anforderungen an Privacy-Shield-zertifizierte
          Unternehmen zur Wahrung eines der EU entsprechenden Datenschutzniveaus
          unberührt.
        </p>
        <p>
          Um für Ihre Daten stets ein angemessenes Schutzniveau zu
          gewährleisten, selbst wenn Ihre Daten aus dem Geltungsbereich der
          DS-GVO hinaus übermittelt werden, bemühen wir uns, mit allen
          Datenempfängern in den USA Verträge zu schließen, die auch die von der
          EU-Kommission erlassenen Standarddatenschutzklauseln umfassen. Darüber
          hinaus ergreifen wir weitere technische und organisatorische
          Maßnahmen, um die personenbezogenen Daten bei der Übermittlung aus dem
          Geltungsbereich der DS-GVO hinaus zu schützen.
        </p>
        <p>
          Weitere Informationen dazu finden Sie nachfolgend bei den einzelnen
          Dienstleistern.
        </p>

        <h2>
          Zwecke der Datenverarbeitung durch die verantwortliche Stelle und
          Dritte
        </h2>
        <p>
          Wir verarbeiten deine personenbezogenen Daten unter Beachtung der
          DS-GVO.
        </p>
        <p>
          Eine Verarbeitung findet nur zu den in der Datenschutzerklärung
          ausgewiesenen Zwecken statt. Eine Verarbeitung zu anderen Zwecken
          findet nicht statt.
        </p>
        <p>
          Wir verarbeiten deine Daten für das Kontaktformular auf Basis von Art.
          6 Abs. 1 S. 1 lit. b) DS-GVO (Vertragsverhältnis). Näheres hierzu
          findest du unter dem Punkt Kontaktformular.
        </p>
        <p>
          Es kann vorkommen, dass wir deine personenbezogenen Daten aufgrund
          Art. 6 Abs. 1 S. 1 lit. c) DS-GVO (Erfüllung gesetzlicher Vorgaben)
          verarbeiten. Dies ist zum Beispiel dann der Fall, wenn wir gesetzliche
          Aufbewahrungsfristen erfüllen müssen.
        </p>
        <p>
          Wir geben deine personenbezogenen Daten an folgende Dritte weiter:
        </p>
        <h3>Amazon Web Services</h3>
        <p>
          Wir hosten unsere Systeme bei Amazon Web Services, Inc., 410 Terry
          Avenue North, Seattle WA 98109, USA („AWS“) ein. Die Systeme werden in
          der EU Region Irland des AWS Netzwerks gehostet.
        </p>
        <p>
          Entsprechend Art. 28 DS-GVO haben wir einen
          Auftragsdatenverarbeitungsvertrag (Data-Processing-Agreement) mit
          Amazon Web Services abgeschlossen. Dabei handelt es sich um einen
          Vertrag, in dem sich AWS unter anderem dazu verpflichtet, die Daten
          der Nutzer zu schützen, entsprechend den festgehaltenen
          Datenschutzbestimmungen im Auftrag von uns zu verarbeiten und
          insbesondere nicht an Dritte weiterzugeben.
        </p>
        <p>
          Nähere Informationen hierzu findest du in{' '}
          <a href="https://aws.amazon.com/de/privacy/?nc1=f_pr">
            der Datenschutzerklärung
          </a>{' '}
          von AWS. Rechtsgrundlage für die Verarbeitung ist das
          Vertragsverhältnis (Art. 6 Abs. 1 S. 1 lit. b) DS-GVO).
        </p>
        <h3>SSL-Verschlüsselung</h3>
        <p>
          Um die Sicherheit deiner Daten bei der Übertragung zu schützen,
          verwenden wir dem aktuellen Stand der Technik entsprechende
          Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
        </p>
        <h3>Kontaktformular Eventspace</h3>
        <p>
          Wenn du uns per <Link to="/kontakt">Kontaktformular</Link> Anfragen
          zukommen lässt, werden deine Angaben aus dem Anfrageformular inklusive
          der von dir dort angegebenen Kontaktdaten zwecks Bearbeitung der
          Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Die
          Weitergabe an Dritte findet nicht statt.
        </p>
        <p>
          Zur Bearbeitung der Anfrage und deren Abwicklung erfolgt die
          Verarbeitung dieser Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. b
          DSGVO.
        </p>
        <p>
          Deine im Kontaktformular eingegebenen Daten verbleiben bei uns, bis du
          uns zur Löschung aufforderst oder der Zweck für die Datenspeicherung
          entfällt (z. B. nach abgeschlossener Bearbeitung deiner Anfrage).
          Unabhängig davon sind wir verpflichtet zwingende gesetzliche
          Bestimmungen insbesondere Aufbewahrungspflichten einzuhalten.
        </p>
        <h2>Google Tag Manager</h2>
        <p>
          Diese Website benutzt den Google Tag Manager. Google Tag Manager ist
          eine Lösung, mit der Vermarkter Website-Tags über eine Oberfläche
          verwalten können. Das Tool Tag Manager selbst (das die Tags
          implementiert) ist eine Cookie-lose Domain und erfasst keine
          personenbezogenen Daten. Das Tool sorgt für die Auslösung anderer
          Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag
          Manager greift nicht auf diese Daten zu. Wenn auf Domain- oder
          Cookie-Ebene eine Deaktivierung vorgenommen wurde, bleibt diese für
          alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert
          werden.
        </p>
        <h2>Externe Verlinkungen</h2>
        <p>
          Wenn du unsere Website über einen externen Link verlässt, gelten die
          Datenschutzrechtlichen Bestimmungen der anderen Website.
        </p>
        <h2>Änderung unserer Datenschutzbestimmungen</h2>
        <p>
          Wir behalten uns vor, diese Datenschutzhinweise anzupassen, damit sie
          stets den aktuellen rechtlichen Anforderungen entsprechen oder um
          Änderungen der Leistungen der CodeCamp:N GmbH in den
          Datenschutzhinweisen umzusetzen, z.B. bei der Einführung neuer
          Services. Für deinen erneuten Besuch gelten dann die neuen
          Datenschutzhinweise.
        </p>
        <h2>Fragen an den Datenschutzbeauftragten</h2>
        <p>
          Wenn du Fragen zum Datenschutz hast, schreibe uns bitte eine E-Mail an
          info@codecamp-n.com oder wende dich direkt an den von der CodeCamp:N
          bestellten Datenschutzbeauftragten unter den oben genannten
          Kontaktdaten.
        </p>
        <div id="cmpvendorlist"></div>
        <script
          src="https://delivery.consentmanager.net/delivery/vendorlist.php?cdid=7d55663111595"
          type="text/javascript"
          async
        ></script>
      </div>
    </section>
  </>
);

export default DatenschutzPage;
